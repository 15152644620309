import React, { useContext } from "react"
import { Divider, Space, Typography, List } from "antd"
import { translate } from "@action"
import { ContextStore } from "@context"
import { useIntl } from "react-intl"

const RecordReview = ({ id, record, maxScore }) => {
  const thisRecord = record.filter(
    item => item.coreCompetencyDefinitionId === id
  )

  const { Text } = Typography

  const isMoreThanOne = (step, record) => {
    return record.filter(item => item.step === step).length > 1
  }

  const { formatMessage } = useIntl()
  const scoreText = formatMessage({ id: "score" })
  const avgScoreText = formatMessage({ id: "avgRating" })
  const attachmentText = formatMessage({ id: "attachment" })
  const commentsText = formatMessage({ id: "comments" })

  return (
    <List
      className="avoid-break"
      bordered
      size="small"
      itemLayout="horizontal"
      dataSource={thisRecord}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={item.staff.name}
            description={
              <div>
                <Space size="small">
                  <div>
                    <Text strong>{scoreText}: </Text>
                    {item.rating + " / " + maxScore}
                  </div>
                  <div>
                    <Text strong>{avgScoreText}: </Text>
                    {isMoreThanOne(item.step, thisRecord)
                      ? item.avgRating + " / " + maxScore
                      : "N / A"}
                  </div>
                  <div>
                    <Text strong>{attachmentText}: </Text>
                    {item.appraisalCoreCompetencyFile &&
                    item.appraisalCoreCompetencyFile.length > 0
                      ? item.appraisalCoreCompetencyFile.map(file => {
                          return file.fileName + ", "
                        })
                      : " - "}
                  </div>
                </Space>
                <div>
                  <Text strong>{commentsText}: </Text>
                  <pre>{item.content ?? "-"}</pre>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  )
}

const CorePrint = ({ principles, record }) => {
  const { locale } = useContext(ContextStore)
  return principles && principles.length > 0
    ? principles.map((item, index) => (
        <div key={item.id} className="avoid-break">
          <Divider className="avoid-break" orientation="left">
            {`${index + 1}. ${translate(item.name, locale)}`}
          </Divider>
          <div
            className="avoid-break"
            dangerouslySetInnerHTML={{
              __html: translate(item.description, locale),
            }}
          />
          <RecordReview
            id={item.coreCompetencyDefinitionId}
            record={record}
            maxScore={item.score}
          />
        </div>
      ))
    : null
}

export default CorePrint
