import React, { useContext } from "react"
import { Divider, Empty, Space, Typography, List } from "antd"
import { translate } from "@action"
import { ContextStore } from "@context"
import { useIntl } from "react-intl"

const RecordList = ({ record, otherRecord }) => {
  let amendRecord = otherRecord
    .filter(item => item.scoreAdjustmentNumber === record.scoreAdjustmentNumber)
    .filter(item => item.title === null)

  amendRecord.unshift(record)

  const { Text } = Typography

  const { formatMessage } = useIntl()
  const titleText = formatMessage({ id: "title" })
  const scoreText = formatMessage({ id: "score" })
  const descriptionText = formatMessage({ id: "description" })
  const attachmentText = formatMessage({ id: "attachment" })
  const commentsText = formatMessage({ id: "comments" })

  return (
    <List
      className="avoid-break"
      bordered
      size="small"
      itemLayout="horizontal"
      dataSource={amendRecord}
      renderItem={amendItem => (
        <List.Item>
          <List.Item.Meta
            title={amendItem.staff.name}
            description={
              <div>
                <Space size="small">
                  {amendItem.title && (
                    <div>
                      <Text strong>{titleText}: </Text>
                      {amendItem.title}
                    </div>
                  )}
                  <div>
                    <Text strong>{scoreText}: </Text>
                    {amendItem.scoreAdjustmentOption
                      ? amendItem.scoreAdjustmentOption.score
                      : "Invaild"}
                  </div>
                  <div>
                    <Text strong>{attachmentText}: </Text>
                    {amendItem.appraisalScoreAdjustmentFile.length > 0
                      ? amendItem.appraisalScoreAdjustmentFile.map(item => {
                          return item.fileName + ", "
                        })
                      : " - "}
                  </div>
                </Space>
                <div>
                  <Text strong>
                    {amendItem.title
                      ? descriptionText + ": "
                      : commentsText + ": "}
                  </Text>
                  <pre>{amendItem.description}</pre>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  )
}

const RecordReview = ({ id, record }) => {
  const thisRecord = record.filter(
    item => item.scoreAdjustmentDefinitionId === id
  )
  const allFirstRecord = thisRecord.filter(item => item.title !== null)

  return allFirstRecord && allFirstRecord.length > 0 ? (
    allFirstRecord.map(item => (
      <RecordList record={item} otherRecord={thisRecord} />
    ))
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

const ExtraPrint = ({ principles, record, score }) => {
  const { locale } = useContext(ContextStore)
  const { formatMessage } = useIntl()
  const scoreText = formatMessage({ id: "totalScore" })
  return principles.map((item, index) => {
    const totalScore = score.find(
      rating => rating.defId === item.scoreAdjustmentDefinitionId
    )
    return (
      <div key={item.id} className="avoid-break">
        <Divider className="avoid-break" orientation="left">
          {`${index + 1}. ${translate(item.name, locale)}`}
        </Divider>
        <div
          className="avoid-break"
          dangerouslySetInnerHTML={{
            __html: translate(item.description, locale),
          }}
        />

        <RecordReview id={item.scoreAdjustmentDefinitionId} record={record} />
        {score && score.length > 0 && (
          <Divider className="avoid-break" orientation="right">
            {scoreText}: {totalScore.totalScore}
          </Divider>
        )}
      </div>
    )
  })
}

export default ExtraPrint
