import React from "react"
import { Descriptions, Row, Col } from "antd"
import { FormattedMessage } from "react-intl"

const PastAppraisalPrint = React.memo(({ pastAppraisal }) => {
  return (
    <div>
      <Row>
        <Col span={8}>
          <Descriptions layout="vertical" column={3}>
            <Descriptions.Item
              label={<FormattedMessage id="appraisalCycleName" />}
              span={2}
            >
              {pastAppraisal.map(e => (
                <>
                  {e.appraisalCycleName}
                  <br />
                </>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions layout="vertical" column={3}>
            <Descriptions.Item
              label={
                <FormattedMessage id="finalScore2" values={{ name: "" }} />
              }
              span={2}
            >
              {pastAppraisal.map(e => (
                <>
                  {e.finalScore}
                  <br />
                </>
              ))}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </div>
  )
})

export default PastAppraisalPrint
