import React, { useContext } from "react"
import { Descriptions, Divider, Row, Col } from "antd"
import { translate } from "@action"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"

const ScoreDetail = ({
  score,
  isNormal,
  coreCompetencyWeighting,
  degree360Weighting,
  siteKPIWeighting,
}) => {
  const { locale } = useContext(ContextStore)
  return (
    <div>
      <Row>
        <Col span={8}>
          <Descriptions
            title={
              <>
                <FormattedMessage id="coreCompetencies" />
                {" (" + coreCompetencyWeighting + "%)"}
              </>
            }
            column={1}
          >
            <Descriptions.Item label={<FormattedMessage id="score" />}>
              {score
                ? score.coreCompetencyScore +
                  " / " +
                  score.coreCompetencyMaxScore
                : null}
            </Descriptions.Item>
            {score &&
              isNormal &&
              score.scoreAdjustmentGroup.map((item, index) => (
                <Descriptions.Item
                  key={index}
                  label={<div>{translate(item.name, locale)}</div>}
                >
                  {item.totalScore + ""}
                </Descriptions.Item>
              ))}
          </Descriptions>
        </Col>

        {score &&
          (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) &&
          isNormal && (
            <Col span={8}>
              <Descriptions
                title={
                  <>
                    <FormattedMessage id="siteKPI" />
                    {" (" + siteKPIWeighting + "%)"}
                  </>
                }
                column={1}
              >
                <Descriptions.Item label={<FormattedMessage id="score" />}>
                  {!score.isSiteKPIPending ? (
                    score.siteKPIScore + " / " + score.siteKPIMaxScore
                  ) : (
                    <FormattedMessage id="tbc" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}

        {score && score.degree360MaxScore > 0 && isNormal && (
          <Col span={8}>
            <Descriptions
              title={
                <>
                  <FormattedMessage id="degree360" />
                  {!score.isDegree360Completed && (
                    <>
                      {" - "}
                      <FormattedMessage id="inProgress" />
                      {" (" + degree360Weighting + "%)"}
                    </>
                  )}
                </>
              }
              column={1}
            >
              <Descriptions.Item label={<FormattedMessage id="score" />}>
                {score
                  ? score.degree360Score + " / " + score.degree360MaxScore
                  : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={8}>
          <Descriptions column={1}>
            <Descriptions.Item label={<FormattedMessage id="subtotal" />}>
              {score ? score.coreCompetencySubtotal : null}
            </Descriptions.Item>
          </Descriptions>
        </Col>

        {score &&
          (score.siteKPIMaxScore > 0 || score.isSiteKPIPending) &&
          isNormal && (
            <Col span={8}>
              <Descriptions column={1}>
                <Descriptions.Item label={<FormattedMessage id="subtotal" />}>
                  {score.siteKPIMaxScore > 0 ? score.siteKPISubtotal : null}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          )}
        {score && score.degree360MaxScore > 0 && isNormal && (
          <Col span={8}>
            <Descriptions column={1}>
              <Descriptions.Item label={<FormattedMessage id="subtotal" />}>
                {score.degree360Subtotal}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        )}
        <Col span={24}>
          <Divider orientation="right">
            <FormattedMessage id="totalScore" /> : {score?.finalScore}
          </Divider>
        </Col>
      </Row>
    </div>
  )
}

export default ScoreDetail
