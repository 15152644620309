import React from "react"
import { Descriptions } from "antd"
import { transformDate } from "@action"
import { FormattedMessage } from "react-intl"
const AppraisalInfo = ({ person, attendanceRecord, isNormal, cycle }) => {
  return (
    <div className="appraisee-information">
      <Descriptions layout="vertical" column={3}>
        <Descriptions.Item label={<FormattedMessage id="fullName" />}>
          {person.name}
        </Descriptions.Item>
        {isNormal && (
          <Descriptions.Item label={<FormattedMessage id="staffNo" />}>
            {person.staffNo}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={<FormattedMessage id="company" />}>
          {person.company}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="site" />}>
          {person.siteOrDepartment}
        </Descriptions.Item>
        <Descriptions.Item label={<FormattedMessage id="position" />}>
          {person.position}
        </Descriptions.Item>
        {isNormal && (
          <Descriptions.Item label={<FormattedMessage id="dateJoined" />}>
            {transformDate(person.dateJoined)}
          </Descriptions.Item>
        )}

        {attendanceRecord && isNormal && (
          <>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="lateness"
                  values={{ name: <FormattedMessage id="times" /> }}
                />
              }
            >
              {attendanceRecord.latenessTimes + ""}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="lateness"
                  values={{ name: <FormattedMessage id="mins" /> }}
                />
              }
            >
              {attendanceRecord.latenessMinutes + ""}
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage id="failToClock" />}>
              {attendanceRecord.failingToClock + ""}
            </Descriptions.Item>
          </>
        )}
        {isNormal && (
          <Descriptions.Item
            label={<FormattedMessage id="yearInCurrentPosition" />}
          >
            {person.yearInCurrentPosition || person.yearInCurrentPosition === 0
              ? person.yearInCurrentPosition + ""
              : "N/A"}
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={<FormattedMessage id="reviewPeriod" />}
          span={2}
        >
          {transformDate(cycle.appraisalStartDate) +
            " to " +
            transformDate(cycle.appraisalEndDate)}
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default AppraisalInfo
