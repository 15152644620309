import React, { useEffect, useState, useRef } from "react"
import {
  AppraisalClient,
  AppraisalTemplateClient,
  CoreCompetencyDefinitionSetClient,
  GoalSettingDefinitionSetClient,
  TemplateType,
} from "@api"
import Loading from "@components/Loading"
import { Typography } from "antd"
import {
  ExtraPrint,
  CorePrint,
  AppraisalInfo,
  ScoreDetail,
  CommentPrint,
  RemarksPrint,
  GoalPrint,
  SubTemplatePrint,
  PastAppraisalPrint,
} from "@components/print"
import "@style/PdfReport.less"
import { transformDate } from "@action"
import { useReactToPrint } from "react-to-print"
import { FormattedMessage } from "react-intl"
import moment from "moment"
import Logo from "@images/ks_logo.png"
import { navigate } from "gatsby"

const getStaffNo = () => {
  if (typeof window !== "undefined") {
    let user = sessionStorage.getItem(
      "oidc.user:" + process.env.REACT_APP_AUTH_URL + ":KaiShing.WebUI"
    )
    user = JSON.parse(user)
    return user ? user.profile.sub : null
  }
  return null
}

const PrintView = ({
  template,
  appraisal,
  pastAppraisal,
  score,
  subTemplate,
}) => {
  const { Title } = Typography

  const isNormal = template.isNormal
  const person = appraisal.appraisal

  const {
    attendanceRecord,
    appraisalCycle: cycle,
    appraisalCoreCompetency: coreRecord,
    appraisalScoreAdjustment: extraRecord,
    appraisalComment: commentRecord,
    appraisalGoalSetting: goalRecord,
    subAppraisal,
  } = appraisal.appraisal

  const appraisalYear =
    moment(cycle.appraisalStartDate).format("YYYY") +
    " - " +
    moment(cycle.appraisalEndDate).format("YYYY")
  const appraisalTitle = `Performance Appraisal ${appraisalYear}`

  const {
    coreCompetencyList: corePrinciples,
    scoreAdjustmentList: extraPrinciples,
    goalSettingList: goalPrinciples,
    appraisalTemplateComment: commentPrinciples,
  } = template

  const extraScore = score?.scoreAdjustmentGroup
  const degree360Score = score?.degree360Score

  const stepList = appraisal.path
  const staffNo = getStaffNo()
  const currentStep = appraisal.currentStep
  const isMyStep = currentStep
    ? stepList[stepList.length - 1].approver.findIndex(
        item => item.staffNo === staffNo
      )
    : -1
  const activeIndex = stepList.findIndex(item => item.status === "Active")
  const currentCommentSteps = commentPrinciples.filter(
    item =>
      item.step <=
      (isMyStep !== -1
        ? currentStep.step
        : activeIndex !== -1
        ? activeIndex - 1
        : stepList.length)
  )
  const remarksHistory = appraisal.appraisal.appraisalHistory.filter(
    item => item.remarks !== null
  )
  const goalPeriod =
    cycle.goalStartDate !== null || cycle.goalEndDate !== null
      ? transformDate(cycle.goalStartDate) +
        " to " +
        transformDate(cycle.goalEndDate)
      : " - "

  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: "pdfReport",
  })

  useEffect(() => {
    if (template) {
      handlePrint()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template])

  return (
    <div ref={componentRef}>
      <div className="session">
        <img
          src={Logo}
          alt="logo"
          style={{ position: "absolute", width: "40px" }}
        />
        <Title level={3} style={{ width: "fit-content", margin: "auto" }}>
          {appraisalTitle}
        </Title>
        <Title level={4}>
          <FormattedMessage id="apprisaeeInfo" />
        </Title>
        <AppraisalInfo
          person={person}
          attendanceRecord={attendanceRecord}
          isNormal={isNormal}
          cycle={cycle}
        />
      </div>

      <div className="session">
        <Title level={4}>
          <FormattedMessage id="pastAppraisal" />
        </Title>
        <PastAppraisalPrint
          pastAppraisal={pastAppraisal}
          staffNo={appraisal?.appraisal.staffNo}
        />
      </div>

      <div className="session">
        <Title level={4}>
          <FormattedMessage id="score" />
        </Title>
        <ScoreDetail
          score={score}
          isNormal={isNormal}
          degree360Weighting={template?.degree360Weighting}
          coreCompetencyWeighting={template?.coreCompetencyWeighting}
          siteKPIWeighting={template?.siteKPIWeighting}
        />
      </div>
      {corePrinciples && corePrinciples.length ? (
        <div className="session">
          <Title level={4}>
            <FormattedMessage id="coreCompetencies" />
          </Title>
          <CorePrint principles={corePrinciples} record={coreRecord} />
        </div>
      ) : null}
      {extraPrinciples && extraPrinciples.length ? (
        <div className="session break-page">
          <Title level={4}>
            <FormattedMessage id="additionalScore" />
          </Title>

          <ExtraPrint
            principles={extraPrinciples}
            record={extraRecord}
            score={extraScore}
          />
        </div>
      ) : null}
      {(remarksHistory && remarksHistory.length) ||
      (currentCommentSteps && currentCommentSteps.length) ? (
        <div className="session break-page">
          {currentCommentSteps && currentCommentSteps.length ? (
            <Title level={4}>
              <FormattedMessage id="comments" />
            </Title>
          ) : null}
          {currentCommentSteps && currentCommentSteps.length ? (
            <CommentPrint
              principles={currentCommentSteps}
              record={commentRecord}
            />
          ) : null}

          {remarksHistory && remarksHistory.length ? (
            <RemarksPrint record={remarksHistory} />
          ) : null}
        </div>
      ) : null}

      {goalPrinciples && goalPrinciples.length ? (
        <div className="session break-page">
          <Title level={4}>
            <FormattedMessage id="goalSetting" />
          </Title>

          <GoalPrint
            principles={goalPrinciples}
            record={goalRecord}
            period={goalPeriod}
          />
        </div>
      ) : null}

      {subTemplate ? (
        <div
          className={
            corePrinciples?.length ||
            extraPrinciples?.length ||
            remarksHistory?.length ||
            currentCommentSteps?.length ||
            goalPrinciples?.length
              ? "session break-page"
              : "session"
          }
        >
          <Title level={3}>
            <FormattedMessage id="degree360" />
          </Title>

          <SubTemplatePrint
            template={subTemplate}
            data={subAppraisal}
            score={degree360Score}
          />
        </div>
      ) : null}
    </div>
  )
}

const PdfReport = ({ id }) => {
  const [isError, setIsError] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [appraisal, setAppraisal] = useState(null)
  const [template, setTemplate] = useState(null)
  const [score, setScore] = useState(null)
  const [subTemplate, setSubTemplate] = useState(null)
  const [pastAppraisal, setPastAppraisal] = useState([])

  const InitPage = async id => {
    if (id === null) {
      setIsError(true)
    } else {
      getAppraisal(id)
    }
  }

  const getAppraisal = async id => {
    const appraisalClient = new AppraisalClient()
    const templateClient = new AppraisalTemplateClient()
    const coreClient = new CoreCompetencyDefinitionSetClient()
    try {
      const appraisalRes = await appraisalClient.get2(id)
      setAppraisal(appraisalRes.content)
      const params = {
        orderBy: "id",
        pageSize: 3,
        pageIndex: 1,
      }
      const resPastAppraisal = await appraisalClient.getLatestAppraisalsQuery(
        id,
        params.pageSize,
        params.pageIndex
      )
      setPastAppraisal(resPastAppraisal.items)
      const score = await appraisalClient.getScore(id)
      setScore(score)
      const appraisalContent = appraisalRes.content
      let templateRes = await templateClient.get2(
        appraisalContent.appraisal.appraisalTemplateId
      )
      if (!!templateRes.coreCompetencyDefinitionSetId) {
        const result = await coreClient.get2(
          templateRes.coreCompetencyDefinitionSetId
        )
        templateRes = {
          ...templateRes,
          templateType: templateRes.type,
          ...result,
          coreCompetencyList: result.coreCompetencyDefinitions,
          scoreAdjustmentList: result.scoreAdjustmentDefinitions,
        }
      }
      if (!!templateRes.goalSettingDefinitionSetId) {
        const goalClient = new GoalSettingDefinitionSetClient()

        const goalRes = await goalClient.get2(
          templateRes.goalSettingDefinitionSetId
        )
        templateRes = {
          ...templateRes,
          ...goalRes,
          goalSettingList: goalRes.goalSettingDefinitions,
        }
      }
      if (
        [TemplateType.Frontline, TemplateType.Degree360].includes(
          templateRes.templateType
        )
      ) {
        window.alert("Invalid Permission")
        navigate("/", { replace: true })
        return
      }
      setTemplate(templateRes)
      if (appraisalContent.appraisal.subAppraisal) {
        const template360Content = await templateClient.get2(
          appraisalContent.appraisal.subAppraisal.appraisalTemplateId
        )

        const subCoreCompetency = await coreClient.get2(
          template360Content.coreCompetencyDefinitionSetId
        )
        setSubTemplate({
          ...template360Content,
          coreCompetencyList: subCoreCompetency.coreCompetencyDefinitions,
        })
      }
      console.log(subTemplate)
      setIsLoad(true)
    } catch (err) {
      //Need handle error
      console.log(err)
    }
  }

  useEffect(() => {
    InitPage(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isError && isLoad ? (
    <div className="pdfReport">
      <PrintView
        template={template}
        appraisal={appraisal}
        pastAppraisal={pastAppraisal}
        subTemplate={subTemplate}
        score={score}
      />
    </div>
  ) : (
    <Loading />
  )
}

export default PdfReport
