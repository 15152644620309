import React, { useContext } from "react"
import { Divider, Empty, List } from "antd"
import { transformDate, translate } from "@action"
import { ContextStore } from "@context"

const CommentReview = ({ id, record, type }) => {
  const list = record.filter(item => item.commentDefinitionId === id)
  return list && list.length > 0 ? (
    <List
      className="avoid-break"
      bordered
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={item.staff.name}
            description={
              type === 0 ? (
                <pre>{item.content}</pre>
              ) : type === 1 ? (
                transformDate(item.content)
              ) : type === 2 ? (
                item.attachment
              ) : (
                " - "
              )
            }
          />
        </List.Item>
      )}
    />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

const CommentPrint = ({ principles, record }) => {
  const { locale } = useContext(ContextStore)

  return principles.map(item => (
    <div key={item.commentDefinitionId} className="avoid-break">
      <Divider className="avoid-break" orientation="left">
        {translate(item.name, locale)}
      </Divider>

      <CommentReview
        id={item.commentDefinitionId}
        record={record}
        type={item.commentType}
      />
    </div>
  ))
}

export default CommentPrint
