import React, { useContext } from "react"
import { Divider, List, Empty, Typography } from "antd"
import { translate } from "@action"
import { ContextStore } from "@context"
import { useIntl } from "react-intl"

const GoalReview = ({ id, record }) => {
  const { locale } = useContext(ContextStore)
  const thisRecord = record.filter(item => item.goalDefinitionId === id)
  const { Text } = Typography
  const { formatMessage } = useIntl()
  const goalText = formatMessage({ id: "goals" })

  const planText = formatMessage({ id: "plan" })

  const trainingPlanText = formatMessage({ id: "trainingPlan" })

  const resultText = formatMessage({ id: "expectedResults" })

  return thisRecord.length > 0 ? (
    <List
      className="avoid-break"
      bordered
      size="small"
      itemLayout="horizontal"
      dataSource={thisRecord}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={item.staff.name}
            description={
              <div>
                <div>
                  <Text strong>{goalText}: </Text>
                  {item.goals}
                </div>
                <div>
                  <Text strong>{planText}: </Text>
                  <pre>{item.plan}</pre>
                </div>
                <div>
                  <Text strong>{trainingPlanText}: </Text>
                  {item.trainingAndDevelopmentPlanOther
                    ? item.trainingAndDevelopmentPlanOther
                    : translate(item.goalSettingPlans, locale)}
                </div>

                <div>
                  <Text strong>{resultText}: </Text>
                  <pre>{item.expectedResults}</pre>
                </div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  )
}

const GoalPrint = ({ principles, record, period }) => {
  const { locale } = useContext(ContextStore)

  const { formatMessage } = useIntl()

  const periodText = formatMessage({ id: "goalPeriod" })

  return (
    <div>
      <Divider className="avoid-break" orientation="left">
        {periodText}: {period}
      </Divider>
      {principles.map((item, index) => (
        <div className="avoid-break">
          <Divider className="avoid-break" orientation="left">
            {`${index + 1}. ${translate(item.name, locale)}`}
          </Divider>
          <GoalReview id={item.goalSettingDefinitionId} record={record} />
        </div>
      ))}
    </div>
  )
}

export default GoalPrint
