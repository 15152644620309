import React, { useContext } from "react"
import { Divider, Typography, Empty, Comment } from "antd"
import { translate } from "@action"
import { FormattedMessage } from "react-intl"
import { ContextStore } from "@context"

const CommentReview = ({ comments }) => {
  return comments && comments.length > 0 ? (
    comments.map((comment, index) => (
      <Comment
        key={"comment" + index}
        author={<FormattedMessage id="subordinate" />}
        content={<pre>{comment.content}</pre>}
      />
    ))
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<FormattedMessage id="inProgress" />}
    />
  )
}

const SubTemplatePrint = ({ score, data, template }) => {
  const { Title } = Typography
  const { locale } = useContext(ContextStore)
  const {
    coreCompetencyList: corePrinciples,
    appraisalTemplateComment: commentPrinciples,
  } = template

  const {
    appraisalCoreCompetency: coreRecord,
    appraisalComment: commentRecord,
  } = data

  const thisRecord = (arr, key, id) => {
    return arr.filter(item => item[key] === id)
  }

  const getAvgRating = (arr, id) => {
    if (arr.length > 0) {
      let newArr = arr.filter(item => item.coreCompetencyDefinitionId === id)

      return (
        newArr.map(item => item.rating).reduce((sum, item) => sum + item) /
        newArr.length
      ).toFixed(1)
    }

    return " - "
  }

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <Title level={4}>
          <FormattedMessage id="coreCompetencies" />
        </Title>
        {corePrinciples && corePrinciples.length > 0
          ? corePrinciples.map((item, index) => (
              <div key={item.id} className="avoid-break">
                <Divider className="avoid-break" orientation="left">
                  {`${index + 1}. ${translate(item.name, locale)}`}
                </Divider>
                <div
                  className="avoid-break"
                  dangerouslySetInnerHTML={{
                    __html: translate(item.description, locale),
                  }}
                />
                <Divider className="avoid-break" orientation="right">
                  <FormattedMessage id="rating" />:
                  {" " +
                    getAvgRating(coreRecord, item.coreCompetencyDefinitionId) +
                    "/ " +
                    item.score}
                </Divider>
              </div>
            ))
          : null}
      </div>
      <Divider orientation="right" className="avoid-break">
        <FormattedMessage id="totalScore" />: {score ? score : " - "}
      </Divider>
      <div style={{ marginBottom: "10px" }} className="break-page">
        <Title level={4}>
          <FormattedMessage id="comments" />
        </Title>
        {commentPrinciples && commentPrinciples.length > 0
          ? commentPrinciples.map(item => (
              <div key={item.id} className="avoid-break">
                <Divider className="avoid-break" orientation="left">
                  {translate(item.name, locale)}
                </Divider>
                <div
                  className="avoid-break"
                  dangerouslySetInnerHTML={{
                    __html: translate(item.description, locale),
                  }}
                />
                <CommentReview
                  id={item.id}
                  comments={thisRecord(
                    commentRecord,
                    "commentDefinitionId",
                    item.commentDefinitionId
                  )}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  )
}

export default SubTemplatePrint
