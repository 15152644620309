import React from "react"
import { Divider, List } from "antd"
import { useIntl } from "react-intl"
const RemarksReview = ({ record }) => {
  const dataSource = [{ ...record }]

  return (
    <List
      className="avoid-break"
      bordered
      size="small"
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta
            title={item.staff}
            description={<pre>{item.remarks}</pre>}
          />
        </List.Item>
      )}
    />
  )
}

const RemarksPrint = ({ record }) => {
  const { formatMessage } = useIntl()

  const commentsText = formatMessage({ id: "comment" })

  return record.map(item => (
    <div key={item.id} className="avoid-break">
      <Divider className="avoid-break" orientation="left">
        {commentsText} - {item.role}
      </Divider>
      <RemarksReview record={item} />
    </div>
  ))
}

export default RemarksPrint
